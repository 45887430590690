import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1023px) {
    width: calc(100% - 6.4px);
    margin-left: 3.2px;
    margin-right: 3.2px;
    margin-bottom: 2px;
  }
`;

const Map = styled.iframe`
  border: 0;
  margin: 8px 0px;
`;

const CompanyMap = () => {
  // const apiKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;
  const googleMapEmbedApiSource =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.8289569572516!2d130.38976507562498!3d33.58378997333682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191805810b38d%3A0x50bf5f071b8dd57f!2z44CSODEwLTAwMjMg56aP5bKh55yM56aP5bKh5biC5Lit5aSu5Yy66K2m5Zu677yS5LiB55uu77yS4oiS77yS77yTIOOCpuOCpOODs-OCsOOCs-ODvOODiOitpuWbug!5e0!3m2!1sja!2sjp!4v1702633923117!5m2!1sja!2sjp';
  return (
    <Root>
      <Map
        width="800"
        height="400"
        frameborder="0"
        src={googleMapEmbedApiSource}
        allowfullscreen
      />
    </Root>
  );
};

export default CompanyMap;
