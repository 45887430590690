import React from 'react';
import styled from 'styled-components';
import PageRoot from '../components/common-components/templates/page-root-v2';
import TriangleTop from '../images-v2/svg/triangle_top.svg';
import TriangleBottom from '../images-v2/svg/triangle_bottom.svg';
import colorPaletteV2 from '../styles/color-palette-v2';
import Map from '../components/company-component-v2/map';
// import Members from '../components/member-components-v2';
import RegnioLogoMark from '../images-v2/svg/regnio_logo-mark_color.svg';
import SEOHeaderV2 from '../components/common-components/templates/page-root-v2/seo-v2';

const WithTriangle = styled.img`
  width: 100%;
  height: auto;
  padding-top: 110px;
  padding-top: 110px;
  margin-top: -110px;
  margin-top: -110px;
`;
const WithTriangleBottom = styled.img`
  width: 100%;
  height: auto;
  background: ${colorPaletteV2.grad2};
`;

const WithTriangleBody = styled.section`
  position: relative;
  .with_triangle_content {
    background-color: ${colorPaletteV2.white};
  }
  .section_sub_title {
    display: flex;
    justify-content: center;
    .logo-mark {
      display: inline-block;
      width: 36px;
      width: 36px;
      height: 36px;
      height: 36px;
    }
  }
  .text_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    margin-top: 48px;
    margin-top: 48px;
    @media (min-width: 520px) {
      flex-direction: row;
    }
    .inner_wrap {
      width: 100%;
      padding-bottom: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-bottom: 20px;
      @media (min-width: 520px) {
        width: 30%;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      @media (min-width: 960px) {
        width: 288px;
        width: 288px;
        margin-bottom: 0;
      }
      .inner_txt {
        text-align: left;
      }
      .inner_txt + .inner_txt {
        margin-top: 14px;
        margin-top: 14px;
      }
      .as-section {
        display: block;
        margin-top: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
        margin-bottom: 16px;
        font-weight: 500;
      }
    }
    .inner_wrap:last-child {
      @media (max-width: 519px) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
`;

const CompanyInfo = styled.div`
  @media (min-width: 960px) {
    margin-top: 180px;
    margin-top: 180px;
    margin-bottom: 100px;
    margin-bottom: 100px;
  }
  .company-info-layout {
    width: 100%;
    @media (min-width: 960px) {
      display: grid;
      /* grid-template-columns: 240px 608px;
      grid-template-columns: 240px 608px; */
      grid-template-areas:
        'logo map'
        'empty info';
      /* grid-template-rows: 300px auto;
      grid-template-rows: 300px auto; */
      grid-row-gap: 64px;
      grid-row-gap: 64px;
    }
    .regnio {
      display: flex;
      font-weight: 700;
      font-size: 32px;
      font-size: 32px;
      justify-content: center;
      @media (min-width: 960px) {
        grid-area: logo;
        width: 240px;
        width: 240px;
        align-items: center;
        justify-content: left;
        font-size: 50px;
        font-size: 50px;
      }
    }
    .map-area {
      width: 100%;
      height: 200px;
      height: 200px;
      margin: 40px auto;
      margin: 40px auto;
      @media (min-width: 520px) {
        height: 300px;
        height: 300px;
      }
      @media (min-width: 960px) {
        grid-area: map;
        width: 608px;
        width: 608px;
        height: 300px;
        height: 300px;
        margin: 0;
      }
    }
    .info {
      grid-area: info;
      font-size: 16px;
      font-size: 16px;
      line-height: 1.8;
      display: grid;
      grid-template-columns: 77px auto;
      grid-template-columns: 77px auto;
      grid-column-gap: 16px;
      grid-column-gap: 16px;
      grid-row-gap: 32px;
      grid-row-gap: 32px;
      @media (min-width: 960px) {
        display: grid;
        grid-template-columns: 128px auto;
        grid-template-columns: 128px auto;
        grid-column-gap: 32px;
        grid-column-gap: 32px;
        grid-row-gap: 28px;
        grid-row-gap: 28px;
      }
    }
  }
`;

const ImportantToUs = styled.section`
  background: ${colorPaletteV2.grad2};
  display: flex;
  justify-content: center;
  padding-top: 80px;
  margin-top: -80px;
  .inner_wrap {
    padding: 32px 0 48px;
    padding: 32px 0 48px;
    @media (min-width: 960px) {
      padding: 100px 0;
      padding: 100px 0;
    }
  }
  .title {
    color: ${colorPaletteV2.white};
    font-size: 24px;
    font-size: 24px;
    margin-bottom: 40px;
    margin-bottom: 40px;
    line-height: 1.5;
    text-align: center;
    @media (min-width: 960px) {
      font-size: 28px;
      font-size: 28px;
    }
    @media (min-width: 960px) {
      font-size: 42px;
      font-size: 42px;
      margin-bottom: 54px;
      margin-bottom: 54px;
      line-height: 1;
      text-align: left;
    }
  }
  .points {
    padding: 0 28px;
    @media (min-width: 960px) {
      padding-left: 0 0 0 32px;
    }
    .point {
      font-size: 20px;
      font-size: 20px;
      line-height: 1.5;
      @media (min-width: 960px) {
        font-size: 24px;
        font-size: 24px;
      }
      @media (min-width: 960px) {
        font-size: 30px;
        font-size: 30px;
        line-height: 1;
      }
      display: grid;
      grid-template-columns: 36px auto;
      grid-template-columns: 36px auto;
      grid-column-gap: 16px;
      grid-column-gap: 16px;
      @media (min-width: 960px) {
        grid-template-columns: 32px auto;
        grid-template-columns: 32px auto;
      }
      align-items: center;
      & + .point {
        margin-top: 24px;
        margin-top: 24px;
        @media (min-width: 960px) {
          margin-top: 32px;
          margin-top: 32px;
        }
      }
      .logo-mark {
        display: inline-block;
        width: 36px;
        width: 36px;
        @media (min-width: 960px) {
          width: 32px;
          width: 32px;
        }
      }
      .txt {
        color: ${colorPaletteV2.white};
      }
    }
  }
`;

const HomePage = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="会社情報" />
      <div className="wrapper-v2 ">
        <CompanyInfo>
          <div className="company-info-layout">
            <div className="regnio">Regnio</div>
            <div className="map-area">
              <Map />
            </div>
            <dl className="info">
              <dt>会社名</dt>
              <dd>
                株式会社Regnio
                <br className="display_only_SP" />
                （リグニオ）
              </dd>
              <dt>設立</dt>
              <dd>2020年11月24日</dd>
              <dt>所在地</dt>
              <dd>
                〒810-0023 <br className="display_only_SP" />
                福岡県福岡市中央区警固2-2-23 <br className="display_only_PC" />
                ウイングコート警固 601
              </dd>
              <dt>事業内容</dt>
              <dd>
                loT/AI等を活用した開発事業
                <br />
                アプリケーション・WEB開発
                <br />
                ソフトウェア導入に関するコンサルティングなど
              </dd>
              <dt>資本金</dt>
              <dd>500万円</dd>
              <dt />
              <dd />
            </dl>
          </div>
        </CompanyInfo>
      </div>

      <WithTriangle src={TriangleTop} id="vision" />
      <WithTriangleBody className="with_triangle">
        <div className="wrapper-v2 with_triangle_content">
          <h2 className="section_title">
            <span className="grad_txt">ビジョン・想い</span>
          </h2>
          <p className="section_sub_title">
            産業の
            <img className="logo-mark" src={RegnioLogoMark} alt="" />
            再点火
          </p>
          <div className="text_wrap">
            <div className="inner_wrap">
              <p className="inner_txt">
                Regnioという社名は、 Re-ignition (再点火) という言葉と I/O
                (Input / Output) という技術用語を掛け合わせたもので、
                <br />
                <strong className="as-section">
                  「技術とクリエイティブの力であらゆるリアルな現場を変革してゆき、産業を再点火していく」
                </strong>
                という意味があります。
              </p>
            </div>
            <div className="inner_wrap">
              <p className="inner_txt">
                「再点火」というと衰退しているものや消えゆくものに再び火を灯すとも受け取られる方もいるかと思いますが、そうではありません。
              </p>
              <p className="inner_txt">
                日本の産業構造を見ると、GDPの大部分は製造、流通、小売等のリアルな産業が中心であり、
                <strong className="as-section">
                  それらの産業をIT技術で「強化」していくことが日本が世界に冠たる国としてい続ける為に必要なことであり、そういった想いを「産業の再点火」という言葉に込めています。
                </strong>
              </p>
            </div>
            <div className="inner_wrap">
              <p className="inner_txt">
                これらリアル産業の現場は、画面で閉じた世界とは違った、無限のデータ量を持つリアルな世界。
              </p>
              <p className="as-section">
                リグニオは、
                <br className="display_only_PC" />
                ハードウェア・ソフトウェア・通信・機械学習/データ分析技術により、リアルな現場をデジタル化し、デザインによるクリエイティブの力を掛け合わせ、
                <br className="display_only_PC" />
                あらゆる現場、事業、産業の変革に挑戦します。
              </p>
            </div>
          </div>
        </div>
      </WithTriangleBody>
      <WithTriangleBottom src={TriangleBottom} />
      <ImportantToUs id="important">
        <div className="inner_wrap">
          <h2 className="title">
            Regnioが
            <br className="display_only_SP" />
            大切にしていること
          </h2>
          <ul className="points">
            <li className="point">
              <img className="logo-mark" src={RegnioLogoMark} alt="" />
              <span className="txt">
                常に難しいことに挑戦し続け、
                <br className="display_only_TB" />
                仕事を全力で楽しむ
              </span>
            </li>
            <li className="point">
              <img className="logo-mark" src={RegnioLogoMark} alt="" />
              <span className="txt">人の可能性、潜在能力を大切にする</span>
            </li>
            <li className="point">
              <img className="logo-mark" src={RegnioLogoMark} alt="" />
              <span className="txt">全ての人に誠実でいる/真剣に向き合う</span>
            </li>
          </ul>
        </div>
      </ImportantToUs>
    </PageRoot>
  );
};

export default HomePage;
